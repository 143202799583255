.pdf-block {
  &-group {
    padding: rem(30) 0;

    position: relative;

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(1200);

  padding: 0 rem(25);
  margin: 0 auto;

  flex-direction: column;

  &__list {
    @include breakpoint($tablet) {
      display: flex;
    }
  }

  &__item {
    display: flex;
    width: 100%;
    max-width: rem(400);

    margin: 0 auto;

    flex-direction: column;

    &:not(:last-of-type) {
      margin-bottom: rem(30);
    }

    @include breakpoint($tablet) {
      width: calc(100% / 3 - 2%);

      margin: 0;

      &:not(:last-of-type) {
        margin-bottom: 0;
        margin-right: 3%;
      }
    }
  }

  &__image {
    width: 100%;
    padding-top: 100%;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &__content {
    display: flex;
    height: 100%;

    padding: rem(20);

    flex-direction: column;

    .button-group {
      padding: 0;
      margin-top: auto;

      text-align: left;
    }
  }

  &__lead {
    text-transform: uppercase;
    letter-spacing: rem(1);
  }

  &__title {
    margin-bottom: rem(30);

    font-size: rem(20);
    font-weight: 300;
    color: #000;
    text-transform: uppercase;
    letter-spacing: rem(1);
  }
}
