.detailed {
  &-group {
    padding: rem(30) 0;

    position: relative;

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }
  }

  display: flex;
  width: 100%;

  margin: 0 auto;

  flex-direction: column;

  @include breakpoint($tablet) {
    flex-direction: row;

    &.is-reversed {
      flex-direction: row-reverse;
    }
  }

  &__images {
    display: flex;
    width: 100%;

    margin-bottom: rem(30);

    &.is-animated {
      position: relative;

      picture {
        &:nth-child(2) {
          position: absolute;
          top: 50%;
          left: 0;

          transform: translateY(-50%);

          animation-name: fade-in-out;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          animation-duration: 10s;
          animation-direction: alternate;
        }
      }
    }

    @include breakpoint($tablet) {
      width: 65%;

      margin-bottom: 0;
    }
  }

  &__image {
    display: flex;
    width: 100%;

    align-self: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    padding: 0 rem(25);

    @include breakpoint($tablet) {
      display: flex;
      flex: 1;

      flex-direction: column;

      padding: rem(36);

      justify-content: center;
    }
  }

  &__title {
    font-size: rem(30);
    font-weight: 300;
    color: #000;
    letter-spacing: rem(2);

    @include breakpoint($tablet) {
      font-size: rem(36);
    }
  }

  &__text {
    width: 100%;

    margin-top: rem(20);

    font-size: rem(16);
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 1;
  }

  45% {
    opacity: 1;
  }

  55% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
