html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  display: flex;
  min-height: 100vh;

  flex-direction: column;

  position: relative;

  font-family: $font-main;
  font-weight: 300;
  font-size: rem(16);
  line-height: 1.3;
  color: map-get($map: $map, $key: text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $color-white;


  @include breakpoint($tablet) {
    flex-direction: row;
  }
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.hide {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;

  font-weight: 700;
}

p {
  margin: 0 0 rem(12) 0;

  line-height: 1.7;

  strong {
    font-weight: 700;
  }
}

ul {
  margin: 0;
  padding: 0;

  list-style: none;
}

a {
  color: map-get($map: $map, $key: text);

  transition: color 0.2s linear;

  &:hover {
    color: map-get($map: $map, $key: orange);
  }
}

