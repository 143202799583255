.header {
  &-group {
    width: 100%;

    padding: 0 rem(25);

    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;

    background: #000;

    &.sticky {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 3;
    }
  }

  display: flex;
  height: rem(59);
  width: 100%;
  max-width: rem(1700);

  margin: 0 auto;

  text-align: center;
  color: $color-white;

  background: #000;

  align-items: center;

  transition: height 0.2s linear;

  @include breakpoint($tablet) {
    height: auto;
    padding: 35px rem(25);

    align-items: flex-start;
  }

  &-link {
    position: relative;

    text-decoration: none;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: rem(2);

      position: absolute;
      bottom: rem(-5);

      opacity: 0;

      background-color: $color-white;

      transition: opacity 0.2s linear;
    }

    &:hover,
    &.is-open {
      &:after {
        opacity: 1;
      }
    }
  }

  &-nav-group {
    display: flex;

    margin-left: auto;
  }

  &-nav {
    display: flex;

    margin-right: rem(25);

    @include breakpoint(0, $tablet) {
      display: none;
    }

    &__item {
      &:not(:last-of-type) {
        margin-right: rem(15);
      }

      font-size: rem(16);
      color: inherit;
      text-transform: uppercase;
      font-weight: 300;

      @include breakpoint($tablet) {
        font-size: rem(24);
      }

      span {
        transition: font-weight 0.2s linear;

        cursor: pointer;
      }

      a {
        color: inherit;
      }

      &.has-subnav.submenu-open {
        span {
          font-weight: 700;
        }
      }
    }

    &__sub {
      display: none;

      margin-top: rem(10);

      position: relative;
      flex-direction: column;

      opacity: 0;

      transition: height 350ms ease-in-out,opacity 750ms ease-in-out;

      &.is-visible {
        display: flex;
        height: 84px;

        opacity: 1;
      }

      &-list {
        position: absolute;
        top: 0;
        left: -50%;

        a {
          text-decoration: none;

          transition: color 0.2s linear;

          &:hover {
            color: map-get($map: $map, $key: orange);
          }
        }
      }

      li {
        margin-bottom: rem(15);
      }
    }
  }

  .social-nav {
    display: flex;

    margin-right: rem(25);

    @include breakpoint(0, $tablet) {
      display: none;
    }

    &__item {
      &:not(:last-of-type) {
        margin-right: rem(10);
      }
    }

    a {
      font-size: rem(16);
      color: inherit;

      transition: color 0.2s linear;

      &:hover {
        color: map-get($map: $map, $key: orange);
      }

      @include breakpoint($tablet) {
        font-size: rem(24);
      }
    }
  }

  &__locales {
    margin-left: auto;

    ul {
      display: flex;

      a, li {
        font-size: rem(16);
        font-weight: 300;
        text-transform: uppercase;
        color: inherit;

        @include breakpoint($tablet) {
          font-size: rem(24);
        }
      }

      a {
        text-decoration: none;
      }

      li {
        &:first-child {
          margin-right: rem(10);
          padding-right: rem(10);
          border-right: 1px solid $color-white;
        }
      }
    }
  }

  .hamburger-group {
    display: flex;

    align-items: center;

    & > span {
      display: inline-flex;

      margin-left: rem(7);

      align-self: center;

      text-transform: uppercase;
      font-weight: 300;
      font-size: rem(16);

      @include breakpoint($tablet) {
        margin-left: rem(15);

        font-size: rem(24);
      }
    }
  }

  .hamburger {
    display: flex;
    width: rem(20);

    flex-direction: column;

    position: relative;

    cursor: pointer;

    @include breakpoint($tablet) {
      width: rem(30);
    }

    span {
      display: inline-flex;
      width: rem(20);
      height: rem(2);

      background-color: $color-white;

      transition: top 0.2s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out;

      &:nth-child(2) {
        margin: rem(3) 0;
      }

      @include breakpoint($tablet) {
        width: rem(30);

        &:nth-child(2) {
          margin: rem(6) 0;
        }
      }
    }

    &.is-open {
      span {
        position: absolute;

        &:nth-child(1) {
          top: 0;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          top: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
