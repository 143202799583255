.contact-section {
  &-group {
    padding: rem(30) 0;

    position: relative;

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(1500);

  padding: 0 rem(25);
  margin: 0 auto;

  font-weight: 300;

  flex-direction: column;

  &__main-title,
  &__title {
    margin-bottom: rem(30);

    font-weight: 300;
    font-size: rem(25);
    color: #000;
    text-transform: uppercase;
    letter-spacing: rem(1);

    @include breakpoint($tablet) {
      font-size: rem(36);
    }
  }

  &__list {
    display: flex;

    flex-wrap: wrap;

    flex-direction: column;

    @include breakpoint(500px) {
      flex-direction: row;

      justify-content: space-between;
    }

    @include breakpoint($tablet) {
      justify-content: flex-start;
    }
  }

  &__item {
    display: flex;
    width: 100%;

    margin-bottom: rem(30);

    flex-direction: column;

    @include breakpoint(500px) {
      width: 48%;
    }

    @include breakpoint($tablet) {
      width: 30%;

      margin-bottom: 0;

      &:not(:nth-of-type(3n)) {
        margin-right: 5%;
      }

      &:not(:nth-of-type(-n+3)) {
        margin-top: rem(50);
      }

      &--long {
        flex: 1;
      }
    }
  }

  &__image {
    margin-bottom: rem(20);

    img {
      width: 100%;

      @include breakpoint($tablet) {
        max-width: rem(435);
      }
    }
  }

  &__content {
    display: flex;

    flex-direction: column;
  }

  &__name {
    margin-bottom: rem(20);

    color: #000;
    font-weight: 300;
    font-size: rem(20);
    letter-spacing: rem(1);
    text-transform: uppercase;
  }

  &__phone {
    margin-bottom: rem(20);

    a {
      text-decoration: none;
    }
  }

  &__mail {
    text-decoration: none;
    font-weight: 700;
  }
}
