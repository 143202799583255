.contact-cta {
  &-group {
    padding: rem(30) 0;

    position: relative;

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(1500);

  padding: 0 rem(25);
  margin: 0 auto;

  flex-direction: column;
  align-items: center;

  &__title {
    margin: 0 0 rem(30) 0;

    font-weight: 300;
    color: #000;
    font-size: rem(30);
    letter-spacing: rem(1);
    text-align: center;

    @include breakpoint($tablet) {
      font-size: rem(36);
    }
  }

  &__subtitle {
    margin: 0 0 rem(30) 0;

    font-size: rem(16);

    @include breakpoint($tablet) {
      font-size: rem(18);
    }
  }

  &__phone {
    margin-bottom: rem(30);

    text-decoration: none;
    font-size: rem(30);
    letter-spacing: rem(1);

    @include breakpoint($tablet) {
      font-size: rem(36);
    }
  }

  &__list {
    li {
      &:not(:last-of-type) {
        margin-bottom: rem(15);
      }
    }

    a {
      font-weight: 400;
      font-size: rem(18);
      letter-spacing: rem(1);
      text-transform: uppercase;

      @include breakpoint($tablet) {
        font-size: rem(20);
      }
    }
  }

}
