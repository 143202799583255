.press-release {
  &:not(:last-of-type) {
    margin-bottom: rem(30);
  }

  &__lead {
    margin-bottom: rem(5);

    color: #000;
  }

  a {
    text-decoration: none;
  }

  &__title {
    margin-bottom: rem(5);

    font-weight: 300;
    font-size: rem(20);
    text-decoration: none;
    color: #000;
    letter-spacing: rem(1);

    transition: color 0.2s linear;

    &:hover {
      color: map-get($map: $map, $key: orange);
    }
  }

  &__link {
    text-decoration: none;
    font-weight: 700;
  }
}
