.slider {
  &-group {
    padding: rem(30) 0;

    position: relative;

    // @include breakpoint($tablet) {
    //   padding: rem(70) 0;
    // }
  }

  display: flex;
  width: 100%;
  max-width: rem(1500);

  padding: 0 rem(25);
  margin: 0 auto;

  flex-direction: column;

  .swiper-container {
    width: 100%;
    // height: rem(200);

    // @include breakpoint($tablet) {
    //   height: rem(300);
    // }

    img {
      width: 100%;
      height: auto;
    }
  }
}
