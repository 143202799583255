.button {
  &-group {
    padding: 0 rem(25);
    margin-top: rem(30);

    text-align: center;

    &--left {
      padding-left: 0;

      text-align: left;
    }
  }

  display: inline-flex;
  padding: rem(10);

  align-self: flex-start;

  font-size: rem(16);
  font-weight: 300;
  color: map-get($map: $map, $key: text);
  text-decoration: none;
  text-transform: uppercase;

  border: rem(1) solid map-get($map: $map, $key: text);

  transition: color 0.2s linear, border 0.2s linear, background-color 0.2s linear;

  cursor: pointer;

  @include breakpoint($tablet) {
    padding: rem(10) rem(15);

    font-size: rem(20);
  }

  &:hover {
    border: rem(1) solid map-get($map: $map, $key: orange);

    color: map-get($map: $map, $key: orange);
  }

  &--alt {
    color: $color-white;

    background-color: map-get($map: $map, $key: orange);

    border: rem(1) solid map-get($map: $map, $key: orange);

    &:hover {
      color: $color-white;

      background-color: darken(map-get($map: $map, $key: orange), 5%);
    }
  }
}
