.two-column {
  &-group {
    padding: rem(30) 0;

    position: relative;

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(1200);

  padding: 0 rem(25);
  margin: 0 auto;

  flex-direction: column;

  @include breakpoint($tablet) {
    flex-direction: row;

    justify-content: space-between;
  }

  &-image {
    display: block;
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;

      object-fit: cover;
    }
  }

  &__item {
    display: flex;
    width: 100%;

    margin-bottom: rem(30);

    flex-direction: column;

    @include breakpoint($tablet) {
      max-width: rem(500);

      margin-bottom: 0;
      padding: 0 rem(20);
    }
  }

  .section-title__title {
    margin-bottom: rem(20);
  }

  .section-title__text {
    margin-top: 0;
    margin-bottom: rem(20);
  }

  .button-group {
    margin-top: auto;
  }
}

.press-group {
  .press__title {
    margin-bottom: rem(30);

    font-weight: 300;
    font-size: rem(36);
    letter-spacing: rem(1);
    color: #000;
    text-align: center;
    text-transform: uppercase;
  }

  .two-column {
    justify-content: center;
    align-items: center;

    &__item {
      max-width: rem(300);

      text-align: center;
    }

    &__item {
      padding: 0 0 rem(30) 0;
      margin: 0 rem(20) rem(30);

      background-color: $color-white;

      box-shadow: 9px 9px 17px rgba(0, 0, 0, 0.5);

      @include breakpoint($tablet) {
        margin-bottom: 0;
      }
    }

    .column__image {
      margin-bottom: rem(30);

      width: 100%;
      max-width: 100%;
    }

    .press__name {
      font-size: rem(18);
      font-weight: 300;
      color: #000;
      letter-spacing: rem(1);
    }

    .press__phone,
    .press__mail {
      text-decoration: none;
      line-height: 1.7;
    }

    .press__phone {
      margin-bottom: rem(12);

      font-weight: 300;
      color: #000;

      &:hover {
        color: map-get($map: $map, $key: orange);
      }
    }

    .press__mail {
      font-weight: 700;
    }
  }
}
