.areas {
  &-group {
    padding: rem(30) 0;

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(1500);

  padding: 0 rem(25);
  margin: 0 auto;

  flex-direction: column;

  @include breakpoint($tablet) {
  }

  &-list-group {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoint($tablet) {
      flex-direction: row;
    }
  }

  &-list {
    display: flex;
    width: 100%;

    position: relative;

    flex-wrap: wrap;

    @include breakpoint(0, $tablet) {
      &:first-of-type {
        margin-bottom: 4%;
      }
    }

    @include breakpoint(400px) {
      justify-content: space-between;
    }

    @include breakpoint($tablet) {
      width: 49%;
    }
  }

  &__item {
    &-group {
      display: flex;
      width: 100%;

      cursor: pointer;

      &:nth-child(1) {
        margin-bottom: 4%;
      }

      &:nth-child(2) {
        margin-bottom: 4%;
      }

      &:nth-child(3) {
        margin-bottom: 4%;
      }

      @include breakpoint(400px) {
        width: 48%;

        &:nth-child(1) {
          margin-bottom: 4%;
        }

        &:nth-child(2) {
          margin-bottom: 4%;
        }

        &:nth-child(3) {
          margin-bottom: 0;
        }
      }
    }

    display: flex;
    width: 100%;

    padding-top: 100%;

    position: relative;

    flex-direction: column;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &__title {
      display: flex;
      width: 100%;
      min-height: 110px;

      padding: rem(20) rem(10);

      position: absolute;
      left: 0;
      bottom: 0;

      justify-content: center;
      align-items: center;

      font-weight: 300;
      font-size: rem(30);
      text-transform: uppercase;
      color: $color-white;
      letter-spacing: rem(1);
      text-align: center;

      pointer-events: none;

      .fabrics & {
        background-color: rgba(91, 88, 82, 0.7);
      }

      .additionals & {
        background-color: rgba(161, 207, 202, 0.7);
      }

      .keyhouse & {
        background-color: rgba(73, 119, 58, 0.7);
      }

      .resource & {
        background-color: rgba(255, 209, 0, 0.7);
      }

      .design & {
        background-color: rgba(148, 54, 139, 0.7);
      }

      .sourcing & {
        background-color: rgba(0, 79, 89, 0.7);
      }

      .sustainable & {
        background-color: rgba(200, 196, 192, 0.7);
      }

      .blue & {
        background-color: rgba(10, 34, 64, 0.7);
      }
    }

    // &.fabrics {
    //   background-image: url('/images/areas/01.jpg');
    // }

    // &.additionals {
    //   background-image: url('/images/areas/02.jpg');
    // }

    // &.keyhouse {
    //   background-image: url('/images/areas/03.jpg');
    // }

    // &.resource {
    //   background-image: url('/images/areas/04.jpg');
    // }
  }

  &__modal {
    display: flex;
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    pointer-events: none;

    opacity: 0;

    transition: opacity 0.2s linear;

    @include breakpoint(0, 400px) {
      height: rem(400);

      position: fixed;
      top: 50%;

      transform: translateY(-50%);
    }

    &.is-visible {
      display: flex;

      flex-direction: column;

      opacity: 1;

      pointer-events: all;
    }

    // &.fabrics {
    //   background-image: url('/images/areas/01.jpg');
    // }

    // &.additionals {
    //   background-image: url('/images/areas/02.jpg');
    // }

    // &.keyhouse {
    //   background-image: url('/images/areas/03.jpg');
    // }

    // &.resource {
    //   background-image: url('/images/areas/04.jpg');
    // }

    &__close {
      display: flex;

      padding: rem(20);

      font-size: rem(30);
      color: $color-white;

      justify-content: flex-end;

      cursor: pointer;
    }

    &__content {
      display: flex;

      margin-top: auto;
      padding: rem(20);

      flex-direction: column;

      background-color: rgba(200, 196, 192, 0.7);

      // .fabrics & {
      //   background-color: rgba(91, 88, 82, 0.7);
      // }

      // .additionals & {
      //   background-color: rgba(161, 207, 202, 0.7);
      // }

      // .keyhouse & {
      //   background-color: rgba(73, 119, 58, 0.7);
      // }

      // .resource & {
      //   background-color: rgba(255, 209, 0, 0.7);
      // }

      // .design & {
      //   background-color: rgba(148, 54, 139, 0.7);
      // }

      // .sourcing & {
      //   background-color: rgba(0, 79, 89, 0.7);
      // }

      // .sustainable & {
      //   background-color: rgba(200, 196, 192, 0.7);
      // }

      // .blue & {
      //   background-color: rgba(10, 34, 64, 0.7);
      // }

      p {
        margin: 0;

        color: #000;
        line-height: 1.5;
      }
    }

    &__title {
      margin: 0 0 rem(15);

      font-weight: 700;
      font-size: rem(30);
      text-transform: uppercase;
      color: #000;
      letter-spacing: rem(1);


    }
  }
}
