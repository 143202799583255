.menu-group {
  display: flex;
  width: 100%;
  height: calc(100% - 59px);

  position: fixed;
  top: rem(59);
  left: 0;
  z-index: 20;

  color: $color-white;

  background-color: #000;

  transform: translateX(-100%);

  transition: 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);

  @include breakpoint($tablet) {
    height: calc(100% - 97px);

    top: rem(97);
  }

  &.is-open {
    transform: translateX(0);
  }

  @include breakpoint($tablet) {
    width: rem(250);
  }
}

.menu {
  display: flex;
  width: 100%;

  padding: rem(50) rem(30);

  &__list {
    li {
      margin-bottom: rem(30);

      font-size: rem(20);
      font-weight: 300;

      @include breakpoint($tablet) {
        font-size: rem(24);
      }

      a {
        color: inherit;
        text-decoration: none;
        // text-transform: uppercase;

        transition: color 0.2s linear;

        &:hover {
          color: map-get($map: $map, $key: orange);
        }
      }
    }
  }
}
