.footer-group {
  display: flex;

  margin-top: auto;

  background-color: map-get($map: $map, $key: gray);
}

.footer {
  display: flex;
  width: 100%;
  max-width: rem(1700);

  margin: 0 auto;
  padding: rem(30) rem(25);

  flex-direction: column;
  align-items: center;

  @include breakpoint($tablet) {
    padding: rem(70) rem(25);

    align-items: unset;
  }

  &-info {
    display: flex;

    justify-content: center;
    flex-direction: column;

    text-align: center;


    @include breakpoint($tablet) {
      flex-direction: row;

      text-align: left;
    }
  }

  &-box {
    display: flex;
    width: 100%;
    max-width: rem(350);

    flex-direction: column;

    &:not(:last-of-type) {
      margin-bottom: rem(50);
    }

    @include breakpoint($tablet) {
      max-width: rem(350);

      &:not(:last-of-type) {
        margin-bottom: 0;
      }
    }

    h1 {
      margin: 0 0 rem(40) 0;

      font-size: rem(36);
      font-weight: 300;
      text-transform: uppercase;
      color: #000;
      letter-spacing: rem(1);
    }

    p {
      margin: 0;

      font-size: rem(14);
      font-weight: 300;
      line-height: 1.7;

      a {
        color: inherit;

        transition: color 0.2s linear;

        &:hover {
          color: map-get($map: $map, $key: orange);
        }
      }
    }
  }

  &-social {
    display: flex;

    margin: rem(30) 0;

    justify-content: center;

    @include breakpoint($tablet) {
      margin: rem(50) 0;
    }

    li {
      padding: 0 rem(10);
    }

    a {
      color: inherit;

      transition: color 0.2s linear;

      &:hover {
        color: map-get($map: $map, $key: orange);
      }
    }
  }

  &-links {
    text-align: center;
    text-transform: uppercase;

    a {
      color: inherit;
      font-weight: 300;
      text-decoration: none;

      transition: color 0.2s linear;

      &:hover {
        color: map-get($map: $map, $key: orange);
      }

      &:not(:last-of-type) {
        &:after {
          content: " | ";
        }
      }
    }
  }
}
