.accordion .a-container .a-panel {
  width: 100%;
  height: auto;
  max-height: 0;

  padding: 0px 10px;

  color: #000;

  opacity: 0;

  overflow: hidden;

  transition: all 0.2s ease-in-out;
}

.accordion .a-container.active .a-panel {
  padding: 15px 10px 10px 10px;
  opacity: 1;
  height: auto;
  max-height: 1500px;
}

.a-btn {
  display: flex;

  margin-bottom: 0;
  padding: rem(20) rem(30);

  color: #000;
  font-size: rem(20);
  font-weight: 300;
  text-transform: uppercase;

  background-color: rgba(145, 136, 130, 0.2);

  transition: background-color 0.2s linear, color 0.2s linear;

  cursor: pointer;

  @include breakpoint($tablet) {
    font-size: rem(36);
  }

  &:before {
    content: '+';
    display: block;
    width: rem(20);

    margin-right: rem(15);

    text-align: center;
  }

  .active & {
    color: $color-white;

    background-color: map-get($map: $map, $key: orange);

    &:before {
      content: '-';
    }
  }
}

.a-container {
  margin-bottom: rem(20);

  @include breakpoint($tablet) {
    margin-bottom: rem(30);
  }
}
