.contact-hero {
  &-group {
    padding: rem(30) 0;

    position: relative;

    background-color: map-get($map: $map, $key: gray);

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }

    @include breakpoint($desktop) {
      padding: rem(150) 0;
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(1500);

  padding: 0 rem(25);
  margin: 0 auto;

  text-align: center;
  font-weight: 300;

  flex-direction: column;

  &__title {
    margin-bottom: rem(30);

    font-weight: 300;
    font-size: rem(40);
    letter-spacing: rem(1);
    color: #000;
    text-align: center;

    @include breakpoint($tablet) {
      margin-bottom: rem(50);

      font-size: rem(60);
    }
  }

  &__subtitle {
    margin-bottom: rem(30);

    font-size: rem(18);

    @include breakpoint($tablet) {
      margin-bottom: rem(50);

      font-size: rem(20);
    }
  }

  &__phone {
    margin-bottom: rem(30);

    font-size: rem(20);
    letter-spacing: rem(1);
    text-decoration: none;

    @include breakpoint($tablet) {
      margin-bottom: rem(50);

      font-size: rem(30);
    }
  }

  &__mail {
    margin-bottom: rem(30);

    font-size: rem(20);
    letter-spacing: rem(1);
    text-decoration: none;

    @include breakpoint($tablet) {
      margin-bottom: rem(50);

      font-size: rem(30);
    }
  }

  &__item {
    letter-spacing: rem(1);
    font-size: rem(18);
    text-transform: uppercase;

    &:not(:last-of-type) {
      margin-bottom: rem(10);
    }
  }
}
