// bold
@font-face {
  font-family: 'bio_sans';
  src: url('/fonts/flat-it_-_bio_sans_bold-webfont.woff2') format('woff2'),
  url('/fonts/flat-it_-_bio_sans_bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

// extrabold
@font-face {
  font-family: 'bio_sans';
  src: url('/fonts/flat-it_-_biosans-extrabold-webfont.woff2') format('woff2'),
  url('/fonts/flat-it_-_biosans-extrabold-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

// light
@font-face {
  font-family: 'bio_sans';
  src: url('/fonts/flat-it_-_biosans-light-webfont.woff2') format('woff2'),
  url('/fonts/flat-it_-_biosans-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

// regular
@font-face {
  font-family: 'bio_sans';
  src: url('/fonts/flat-it_-_biosans-regular-webfont.woff2') format('woff2'),
  url('/fonts/flat-it_-_biosans-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

// Fontello icons
@font-face {
  font-family: 'mfs-icons';
  src: url('/fonts/mfs-icons.eot?7323471');
  src: url('/fonts/mfs-icons.eot?7323471#iefix') format('embedded-opentype'),
  url('/fonts/mfs-icons.woff2?7323471') format('woff2'),
  url('/fonts/mfs-icons.woff?7323471') format('woff'),
  url('/fonts/mfs-icons.ttf?7323471') format('truetype'),
  url('/fonts/mfs-icons.svg?7323471#mfs-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'mfs-icons';
    src: url('../font/mfs-icons.svg?7323471#mfs-icons') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "mfs-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;

  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-search:before { content: '\e800'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-cancel:before { content: '\e801'; } /* '' */
