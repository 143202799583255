.team {
  &-group {
    padding: rem(30) 0;

    position: relative;

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(1500);

  padding: 0 rem(25);
  margin: 0 auto;

  flex-direction: column;

  @include breakpoint(500px) {
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: flex-start;
  }

  &__item {
    display: flex;
    width: 100%;

    padding-top: 100%;
    margin-bottom: 4%;

    position: relative;

    flex-direction: column;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @include breakpoint(500px) {
      width: 48%;

      padding-top: 48%;
    }

    @include breakpoint(500px, $desktop) {
      &:nth-child(odd) {
        margin-right: 3.5%;
      }
    }

    @include breakpoint($desktop) {
      width: 31%;

      padding-top: 31%;
      margin-right: 3.5%;

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }

    @include breakpoint(1300px) {
      width: 23%;

      padding-top: 23%;
      margin-bottom: 3%;
      margin-right: 2.66%;

      &:nth-of-type(3n) {
        margin-right: 2.66%;
      }

      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
  }

  &__info {
    display: flex;
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    flex-direction: column;
    justify-content: center;

    text-align: center;
    color: #000;

    background-color: rgba(255, 255, 255, 0.8);

    opacity: 0;

    transition: opacity 0.2s linear;

    &:hover {
      opacity: 1;
    }
  }

  &__name {
    margin-bottom: rem(10);

    font-size: rem(28);
    font-weight: 300;
  }

  &__job {
    margin-bottom: 0 0rem(10);

    font-size: rem(20);
    font-weight: 300;
  }

  &__mail {
    margin-bottom: rem(5);

    font-size: rem(18);
    font-weight: 400;
    text-decoration: none;
    color: map-get($map: $map, $key: text);
  }

  &__phone {
    margin: 0;

    font-size: rem(18);
    font-weight: 400;
    text-decoration: none;
    color: #000;
  }

  &__extra {
    margin-top: rem(10);
  }
}
