.home-navigation {
  &-group {
    padding: rem(30) 0;

    position: relative;

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(1500);

  padding: 0 rem(25);
  margin: 0 auto;

  flex-direction: column;

  &__bg {
    display: block;
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    opacity: 0.3;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  &__list {
    display: flex;

    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    width: 100%;

    padding: 0 rem(10);
    margin: rem(30) 0;

    flex-direction: column;
    align-items: center;

    text-align: center;
    text-decoration: none;

    @include breakpoint(480px) {
      width: calc(100%/2);
    }

    @include breakpoint(700px) {
      width: calc(100%/3);
    }

    @include breakpoint(900px) {
      width: calc(100%/4);
    }
  }

  &__icon {
    display: flex;
    width: 100%;
    max-width: rem(180);
  }

  &__title {
    margin-bottom: rem(15);

    font-weight: 300;
    font-size: rem(30);
    letter-spacing: rem(1);
    color: #000;
    text-transform: uppercase;
  }

  &__text {
    width: 90%;

    font-weight: 300;
    font-size: rem(16);
    line-height: 1.4;
  }
}
