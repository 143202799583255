/*
  Font variables
*/
$font-main: 'bio_sans', sans-serif;

/*
  Breakpoints
*/
$desktop: 1024px;
$tablet: 768px;

/*
  Color and color maps
*/

$color-white: #ffffff;
$color-error: red;
$color-warning: orange;
$color-success: darkseagreen;
$color-info: cornflowerblue;

// User login/password reset colors
$color-map-admin-user: (
green: #00ce81,
label:#555555,
link: #808080,
link-hover: #b23b3b
);

$map: (
orange: #DF9400,
gray: #F9F9F9,
gray-1: #EBEBEB,
gray-2: #B3B2B2,
text: #9D9D9C,
);
