.single-image {
  display: flex;

  justify-content: center;

  &__image {
    display: inline-flex;
    width: 100%;
    max-width: rem(900);

    margin: 0 auto;

    img {
      width: 100%;
      height: auto;
    }
  }
}
