.hero {
  display: flex;
  width: 100%;
  max-width: rem(2000);

  margin: 0 auto;

  position: relative;

  pointer-events: none;

  .hero--video &,
  .hero--image &,
  .hero--slider & {
    padding-top: 56.25%;

    @include breakpoint($tablet) {
      padding-top: 50.2%;
    }

    video {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      object-fit: cover;
    }
  }

  &__image {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    object-fit: cover;

    img {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    width: 100%;

    flex-direction: column;

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;

    transform: translate(-50%, -50%);
  }

  &__title {
    color: $color-white;
    text-shadow: rem(3) rem(3) rem(7) rgba(0, 0, 0, 0.8);
    font-size: rem(25);
    text-transform: uppercase;
    letter-spacing: rem(3);
    text-align: center;

    @include breakpoint($tablet) {
      font-size: rem(40);
    }
  }

  &__subtitle {
    margin: rem(10) 0 0 0;

    text-shadow: rem(3) rem(3) rem(7) rgba(0, 0, 0, 0.8);
    text-align: center;
    color: $color-white;
    font-size: rem(18);
    font-weight: 400;

    @include breakpoint($tablet) {
      margin: rem(20) 0 0 0;

      font-size: rem(20);
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

}
