.travel {
  &-group {
    padding: rem(30) 0;

    position: relative;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(1500);

  padding: 0 rem(25);
  margin: 0 auto;

  flex-direction: column;

  @include breakpoint($desktop) {
    flex-direction: row;

    .travel__title-group {
      width: 25%;
    }

    .travel__content {
      flex: 1;
    }
  }

  &__content {
    display: flex;

    flex-direction: column;

    @include breakpoint($tablet) {
      flex-direction: row;
    }
  }

  &__item {
    display: flex;
    width: 100%;
    max-width: rem(400);

    margin: 0 auto;

    align-items: center;

    overflow: hidden;

    flex-direction: column;

    &:not(:last-of-type) {
      margin-bottom: rem(40);
    }

    @include breakpoint($tablet) {
      &:not(:last-of-type) {
        margin-bottom: 0;
      }
    }

    & > img {
      width: 100%;
      max-width: rem(50);
      height: auto;
    }

    .button {
      align-self: center;

      margin-top: auto;
    }
  }

  &__title {
    margin: rem(30) 0;

    font-weight: 300;
    font-size: rem(20);
    color: #000;
    text-transform: uppercase;
    letter-spacing: rem(1);
  }
}
