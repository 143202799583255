.section-title {
  display: flex;

  margin-bottom: rem(30);

  align-items: center;

  &__icon {
    font-size: rem(60);

    @include breakpoint($tablet) {
      font-size: rem(80);
    }

    &:before {
      display: flex;

      margin-left: 0;
    }

  }

  &__lead {
    margin-bottom: rem(10);

    font-size: rem(16);
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: rem(2);

    @include breakpoint($tablet) {
      margin-bottom: rem(14);

      font-size: rem(18)
    }
  }

  &__title {
    font-size: rem(30);
    font-weight: 300;
    color: #000;
    text-transform: uppercase;
    letter-spacing: rem(2);

    @include breakpoint($tablet) {
      font-size: rem(36);
    }
  }

  &__text {
    width: 100%;
    max-width: rem(500);

    margin-top: rem(20);

    font-size: rem(16);
  }
}
