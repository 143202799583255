.centered-text {
  &-group {
    padding: rem(30) 0;

    position: relative;

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(900);

  padding: 0 rem(25);
  margin: 0 auto;

  flex-direction: column;

  &.is-centered {
    text-align: center;
  }

  &__lead {
    margin-bottom: rem(10);

    font-size: rem(16);
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: rem(2);

    @include breakpoint($tablet) {
      margin-bottom: rem(14);

      font-size: rem(18)
    }
  }

  &__title {
    font-size: rem(30);
    font-weight: 300;
    color: #000;
    text-transform: uppercase;
    letter-spacing: rem(2);

    @include breakpoint($tablet) {
      font-size: rem(36);
    }
  }

  &__text {
    width: 100%;

    margin-top: rem(20);

    font-size: rem(16);
  }
}
