.image-text {
  &-group {
    padding: rem(30) rem(25);

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }

    &--gray {
      background-color: map-get($map: $map, $key: gray-1);
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(1500);

  padding: 0;
  margin: 0 auto;

  flex-direction: column;

  @include breakpoint($tablet) {
    flex-direction: row;

    justify-content: space-between;

    &--reversed {
      flex-direction: row-reverse;
    }
  }

  &__image {
    display: flex;
    width: 100%;

    align-items: center;
    justify-content: center;

    @include breakpoint($tablet) {
      width: 48%;
    }

    img {
      width: 100%;
      max-width: rem(400);
      height: auto;

      @include breakpoint($tablet) {
        max-width: none;
      }
    }
  }

  &__content {
    &-group {
      display: flex;
      width: 100%;

      padding: rem(30) rem(30) rem(30) 0;

      .image-text--reversed & {
        padding-left: rem(30);
        padding-right: 0;
      }

      @include breakpoint($tablet) {
        width: 48%;

        align-items: center;

        .has-no-image & {
          width: 100%;

          justify-content: center;
        }
      }
    }

    display: flex;

    flex-direction: column;

    .button {
      margin: rem(30) 0 rem(20);
    }
  }

  &__lead {
    margin: 0 0 rem(12) 0;

    color: map-get($map: $map, $key: text);
    text-transform: uppercase;
    font-size: rem(12);
    font-weight: 300;
    letter-spacing: rem(1);

    @include breakpoint($tablet) {
      font-size: rem(14);
    }
  }

  &__title {
    margin: 0 0 rem(30) 0;

    color: #000;
    text-transform: uppercase;
    font-size: rem(36);
    font-weight: 300;
    letter-spacing: rem(1);
  }

  &__text {
    margin: 0 0 rem(12) 0;

    strong {
      font-weight: 700;
    }
  }

  &__bold {
    margin: 0 0 rem(12) 0;

    font-weight: 700;
    font-size: rem(27);
  }

  &__extra {
    margin-bottom: 0;

    font-weight: 700;
  }

  &__links {
    span {
      &:not(:last-of-type) {
        &:after {
          content: ' | ';
        }
      }
    }

    a {
      font-weight: 700;
      text-decoration: none;
    }
  }

  &__columns {
    display: flex;

    flex-direction: column;

    @include breakpoint($tablet) {
      flex-direction: row;
    }
  }

  &__column {
    width: 100%;

    margin-right: rem(20);
    margin-bottom: rem(30);

    @include breakpoint($tablet) {
      width: calc(100% / 3);

      margin-right: rem(20);
      margin-bottom: 0;
    }
  }

  &__column-title {
    margin-bottom: rem(20);

    font-size: rem(20);
    font-weight: 300;
    letter-spacing: rem(1);
    color: #000;
    text-transform: uppercase;
  }
}
