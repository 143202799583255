.cta-text {
  &-group {
    padding: rem(30);

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(1500);

  padding: 0 rem(25);
  margin: 0 auto;

  flex-direction: column;

  text-align: center;

  &__title {
    margin: 0 0 rem(30) 0;

    font-weight: 700;
    font-size: rem(30);
    color: #000;
    text-transform: uppercase;
    letter-spacing: rem(2);

    span {
      display: flex;

      justify-content: center;

      color: map-get($map: $map, $key: gray-2);
    }

    @include breakpoint($tablet) {
      font-size: rem(50);
    }
  }

  &__additional {
    margin: 0;

    text-transform: uppercase;
    font-size: rem(20);
    color: map-get($map: $map, $key: gray-2);

    @include breakpoint($tablet) {
      font-size: rem(30);
    }
  }
}
