.covid-block {
  &-group {
    padding: rem(30) 0;

    position: relative;

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(1500);

  padding: 0 rem(25);
  margin: 0 auto;

  flex-direction: row;

  &__bg {
    display: block;
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    opacity: 0.3;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  &__icons {
    display: none;
    flex: 1;

    &--left {
      margin-right: rem(20);
    }

    &--right {
      margin-left: rem(20);
    }

    @include breakpoint($tablet) {
      display: flex;
    }

    .covid-block__icon {
      flex: 1;
    }
  }

  &__icon {
    text-align: center;

    img {
      width: 100%;
      max-width: rem(70);
    }
  }

  &__content {
    width: 100%;
    max-width: rem(900);

    text-align: center;
  }

  &__title {
    margin: 0 0 rem(30) 0;

    color: #000;
    text-transform: uppercase;
    font-size: rem(36);
    font-weight: 300;
  }

  &__text {
    margin: 0 0 rem(12) 0;

    font-size: rem(16);

    strong {
      font-weight: 700;
    }
  }
}
