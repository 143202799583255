.news {
  &-group {
    padding: rem(30) 0;

    position: relative;

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(1500);

  padding: 0 rem(25);
  margin: 0 auto;

  flex-direction: column;

  &__list {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(500px) {
      justify-content: space-between;
    }
  }

  &__item {
    display: flex;
    width: 100%;

    flex-direction: column;

    &:not(:last-of-type) {
      margin-bottom: rem(30);
    }

    @include breakpoint(500px) {
      width: 48%;
    }

    @include breakpoint($tablet) {
      width: 23.5%;
    }
  }

  &__image {
    width: 100%;

    padding-top: 100%;
    margin-bottom: rem(15);

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &__date {
    padding: 0 rem(10);
  }

  &__title {
    margin: 0 0 rem(20) 0;
    padding: 0 rem(10);

    color: #000;
    font-weight: 300;
    font-size: rem(20);
    text-transform: uppercase;
    letter-spacing: rem(1);

    @include breakpoint($tablet) {
      font-size: rem(30);
    }
  }

  &__desc {
    margin: 0 ;
    padding: 0 rem(10);

    line-height: 1.4;

    @include breakpoint($tablet) {
      font-size: rem(16);
    }
  }
}
