.hotel {
  &-group {
    padding: rem(30) 0;

    position: relative;

    @include breakpoint($tablet) {
      padding: rem(70) 0;
    }
  }

  display: flex;
  width: 100%;
  max-width: rem(1500);

  padding: 0 rem(25);
  margin: 0 auto;

  flex-direction: column;

  &__content {
    display: flex;

    flex-direction: column;

    @include breakpoint($tablet) {
      flex-direction: row;
    }

    &-left,
    &-right {
      flex: 1;
    }

    &-right {
      .featured & {
        padding: rem(20);

        @include breakpoint($tablet) {
          padding: rem(36);
        }
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__title {
    margin-bottom: rem(30);

    font-weight: 300;
    font-size: rem(25);
    letter-spacing: rem(1);
    text-transform: uppercase;
  }

  &__address {
    margin-bottom: rem(30);
    line-height: 1.5;
  }

  &__tel {
    text-transform: uppercase;

    a {
      display: block;

      margin-top: rem(20);

      color: inherit;
      text-transform: none;
      text-decoration: none;
      font-weight: 700;
      color: map-get($map: $map, $key: text);

      &:hover {
        color: map-get($map: $map, $key: orange);
      }
    }

    .featured & {
      a {
        display: inline-block;

        margin-top: 0;

        font-weight: 300;
        color: #000;

        &:hover {
          color: map-get($map: $map, $key: orange);
        }
      }
    }
  }

  &__distance {
    text-transform: uppercase;
  }

  &__mail,
  &__web {
    display: flex;

    font-weight: 700;
    line-height: 1.5;
    text-decoration: none;
    text-transform: uppercase;
  }

  .a-container.active.featured .a-panel {
    padding: 0;
  }

  .a-container.active .a-panel {
    padding: rem(30);
  }

  .a-container .a-panel.has-bg {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}
